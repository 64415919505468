import React from 'react'
import { graphql, Link, navigate } from 'gatsby'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import addToMailchimp from 'gatsby-plugin-mailchimp'

import encode from '../helpers/netlifyFormEncode'
import Seo from '../components/Seo'
import { CheckboxInput, RadioInput, SelectInput, Textarea, TextInput } from '../components/FormikInputs'

import * as styles from '../styles/pages/book-an-appointment.module.scss'

const formInitialValues = {
  firstName: '',
  lastName: '',
  email: '',
  cellphone: '',
  alternativePhoneNumber: '',
  studentAdviserConsultation: false,
  careerAssessment: false,
  learningMethod: '',
  studyField: '',
  course: '',
  message: ''
}

const formValidationSchema = Yup.object({
  firstName: Yup.string().required(),
  lastName: Yup.string().required(),
  email: Yup.string().email().required(),
  cellphone: Yup.string().required(),
  alternativePhoneNumber: Yup.string().required(),
  studentAdviserConsultation: Yup.boolean(),
  careerAssessment: Yup.boolean(),
  learningMethod: Yup.string(),
  studyField: Yup.string(),
  course: Yup.string(),
  message: Yup.string(),
})

const BookingPage = ({ data }) => {
  const studyFields = data.allSanityStudyField.nodes
  const courses = data.allSanityCourse.nodes

  const studyFieldOptionElements = studyFields.map(field => (
    <option key={field.title} value={field.title}>{field.title}</option>
  ))

  const courseOptionElements = (studyField) => {
    const filteredCourses = courses.filter(course => {
      const courseStudyFields = course.studyFields.map(field => field.title)
      return courseStudyFields.includes(studyField)
    })

    const filteredCourseOptionElements = filteredCourses.map(course => (
      <option key={course.title} value={course.title}>{course.title}</option>
    ))

    return (<>
      <option value=''>Choose a course</option>
      {filteredCourseOptionElements}
    </>)
  }

  const formSubmitHandler = async (values, { resetForm }) => {
    // console.log('Submitting');
    // console.log(values);

    try {
      // NETLIFY FORM
      await fetch("/", {
        method: "POST",
        headers: { "Content-Type": "application/x-www-form-urlencoded" },
        body: encode({
          "form-name": "book-an-appointment",
          ...values
        })
      })
      
      // CAMPUSONLINE POST
      await fetch("https://unicollege.campusonline.co.za/registration/student-registration.php", {
        method: "POST",
        mode: "no-cors",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
          requestType: 'booking',
          ...values
        })
      })

      // if (values.receiveUpdates) {
        await addToMailchimp(values.email, {
          FNAME: values.firstName,
          LNAME: values.lastName,
          ...values.learningMethod === 'Full Time' && { 'group[80825][1]': true },
          ...values.learningMethod === 'Part Time' && { 'group[80825][2]': true },
          // ...values.learningMethod === 'Online' && { 'group[80825][4]': true },  //remove as requested
          ...values.learningMethod === 'Workshops' && { 'group[80825][16384]': true },
        })
      // }

      resetForm()
      navigate('/thank-you-appointment/')
    } catch (error) {
      alert('An error occurred when submitting the form, please try again.')
      console.log(error);
    }
  }

  return (
    <>
      <Seo title="Book an Appointment with a Career Advisor" pathname='/book-an-appointment' />
      <main className={`${styles.bookingPage} page-container-lg`}>
        <h1>Book an Appointment</h1>
        <p>Our friendly <Link to="/our-team">Student Advisers</Link> are experienced in helping prospective students decide which course will best suit your aptitude and career goals. Take a campus tour, chat about student life and find out the career study paths available to you. Our campus is situated at 17 Clew Street, Monument (we are easily visible from Voortrekker Rd).</p>
        <p>Request an appointment by completing the form below:</p>

        <Formik
          initialValues={formInitialValues}
          validationSchema={formValidationSchema}
          onSubmit={formSubmitHandler}
        >
          {({ isSubmitting, values }) => (
            <Form 
              name="book-an-appointment"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              className={`${styles.bookingForm} form-primary`}
            >
              <input type="hidden" name="form-name" value="book-an-appointment" />
              <TextInput
                label="First Name"
                name="firstName"
                type="text"
              />
              <TextInput
                label="Last Name"
                name="lastName"
                type="text"
              />
              <TextInput
                label="Email"
                name="email"
                type="email"
              />
              <TextInput
                label="Cellphone Number"
                name="cellphone"
                type="tel"
              />
              <TextInput
                label="Alternative Phone Number"
                name="alternativePhoneNumber"
                type="tel"
              />

              <fieldset>
                <legend>I would like:</legend>
                <CheckboxInput name="studentAdviserConsultation">
                  A consultation and chat with a Student Adviser about my career and study options
                </CheckboxInput>
                <CheckboxInput name="careerAssessment">
                  To complete a Career Indicator Assessment and get feedback on which careers and study options best suit my personality and interests
                </CheckboxInput>
              </fieldset>

              <fieldset>
                <legend>What learning method are you interested in?</legend>
                <RadioInput name="learningMethod" value="Full Time">
                  Full Time
                </RadioInput>
                <RadioInput name="learningMethod" value="Part Time">
                  Part Time
                </RadioInput>
                {/* <RadioInput name="learningMethod" value="Online">
                  Online
                </RadioInput> */}
                <RadioInput name="learningMethod" value="Workshops">
                  Workshops
                </RadioInput>
                {/* <CheckboxInput name="learningMethods" value="Full Time">
                  Full Time
                </CheckboxInput>
                <CheckboxInput name="learningMethods" value="Part Time">
                  Part Time
                </CheckboxInput>
                <CheckboxInput name="learningMethods" value="Online">
                  Online
                </CheckboxInput>
                <CheckboxInput name="learningMethods" value="Workshops">
                  Workshops
                </CheckboxInput> */}
              </fieldset>

              <fieldset>
                <legend>What course/study field are you interested in?</legend>
                <SelectInput label="Study Field" name="studyField">
                  <option value="">Choose a study field</option>
                  {studyFieldOptionElements}
                </SelectInput>
                <SelectInput label="Course" name="course">
                  {!values.studyField 
                    ? <option value=''>Please choose a study field first</option>
                    : courseOptionElements(values.studyField)
                  }
                </SelectInput>
              </fieldset>

              <Textarea label="Message" name="message" className="c-span-full" rows="10" />

              <span className="c-span-full">
                <button type="submit" disabled={isSubmitting}>Submit</button>
              </span>
            </Form>
          )}
        </Formik>
      </main>
    </>
  )
}

export const query = graphql`
  query BookingPageQuery {
    allSanityStudyField(sort: {fields: title}) {
      nodes {
        title
      }
    }
    allSanityCourse(sort: {fields: title}) {
      nodes {
        title
        studyFields {
          title
        }
      }
    }
  }
`

export default BookingPage